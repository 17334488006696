import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";

import { useFirebaseConfig } from "./FirebaseConfigContext";
import { useAnalyticsUserData } from "./AnalyticsUserData";
import { useVWOContext } from "./vwoContext";
// import { useUrlContext } from "./urlContext";
import { useEvent } from "./EventContext";

const AmplitudeContext = createContext();

export const useAmplitude = () => useContext(AmplitudeContext);

export const AmplitudeProvider = ({ children }) => {
  const { abTestCampaignName, abTestStartCampaignDate } = useFirebaseConfig();
  const { analyticsUserData } = useAnalyticsUserData();
  const { variantNumber } = useVWOContext();
  // const { queryParams } = useUrlContext();
  const { userIdentifyEvent } = useEvent();

  const prevValuesRef = useRef({});

  const findLocalUserData = useCallback(() => {
    const userData =
      JSON.parse(localStorage.getItem("MemoverseUserData")) || {};
    const quizData =
      JSON.parse(localStorage.getItem("MemoverseQuizData")) || {};

    return {
      name: userData.userName || quizData.firstName || "",
      email: userData.userEmail || quizData.Email || "",
    };
  }, []);

  const identifyUser = useCallback(
    (key, value) => {
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value !== prevValuesRef.current[key]
      ) {
        userIdentifyEvent(key, value);
        prevValuesRef.current[key] = value;
      }
    },
    [userIdentifyEvent]
  );
  const { name, email } = findLocalUserData();

  useEffect(() => {
    const dataToSend = [
      { key: "user_name", value: name },
      { key: "user_email", value: email },
      { key: "user_age", value: analyticsUserData.user_age },
      { key: "user_gender", value: analyticsUserData.user_gender },
      { key: "user_ab_test_number", value: variantNumber },
      { key: "ab_test_name", value: abTestCampaignName },
      { key: "ab_test_start_date", value: abTestStartCampaignDate },
    ];

    dataToSend.forEach(({ key, value }) => identifyUser(key, value));

    // Object.entries(queryParams).forEach(([key, value]) => {
    //   identifyUser(`utm_${key}`, value);
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    name,
    email,
    analyticsUserData,
    variantNumber,
    abTestCampaignName,
    abTestStartCampaignDate,
    // queryParams,
  ]);

  return (
    <AmplitudeContext.Provider value={{}}>{children}</AmplitudeContext.Provider>
  );
};
