import React, { useEffect } from "react";
import { useTimer } from "../../../../../context/TimerContext";
import { useABtest } from "../../../../../context/ABTestContext";
import { useConfig } from "../../../../../context/ConfigDataContext";

import styles from "./ToggleButton.module.css";

const ToggleButton = ({ handleToggle }) => {
  const { findDataComponent } = useConfig();

  const { timer } = useTimer();

  const { isActive, setIsActive } = useABtest();

  const data = findDataComponent(2, "ToggleButton");

  useEffect(() => {
    if (timer <= 0) {
      setIsActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  return (
    <div className={styles.toggleButton}>
      {isActive ? (
        <div className={styles.toggleButtonEnabledTrialText}>
          <span className={styles.toggleButtonTextTop}>{data.enableText}</span>
        </div>
      ) : (
        <span className={styles.toggleButtonText}>{data.disableText}</span>
      )}

      <div
        className={`${styles.toggleButtonInner} ${
          isActive ? styles.active : ""
        }`}
        onClick={handleToggle}
      >
        <div className={styles.toggleButtonSwitch}></div>
      </div>
    </div>
  );
};

export default ToggleButton;
