import React, { useEffect, useState, useMemo, useRef } from "react";
import { useConfig } from "../../../context/ConfigDataContext";
import { useFirebaseConfig } from "../../../context/FirebaseConfigContext";
import { format, addDays, addMonths } from "date-fns";
import { useScroll } from "../../../context/ScrollContext";

import TopComponent from "./TopComponent/TopComponent";
import TopComponentVariantB from "./TopComponentVariantB/TopComponentVariantB";
import Header from "./Header/Header";
import StepsContainer from "./StepsContainer/StepsContainer";
import BottomComponent from "./BottomComponent/BottomComponent";
import FooterContainer from "../../Plan/RedesignPaymentPage/FooterContainer/FooterContainer";

import styles from "./RedesignThankYou.module.css";
import Loader from "../../templates/Loader/Loader";

const RedesignThankYou = () => {
  const { abTestDesignVariant } = useFirebaseConfig();
  const { findDataComponent } = useConfig();

  const [userName, setUserName] = useState("");
  const [orderData, setOrderData] = useState({});
  const [showHideParagraph, setShowHideParagraph] = useState(true);

  const dataComponent = findDataComponent(2, "RedesignThankYou");

  const isNewPath = useMemo(() => {
    const currentPath = window.location.pathname;
    const targetPath = "/new/thankyou";

    return !currentPath.includes(targetPath);
  }, []);

  useEffect(() => {
    setShowHideParagraph(isNewPath);
  }, [isNewPath]);

  useEffect(() => {
    const storedData = localStorage.getItem("MemoverseUserName");
    const userNameData = JSON.parse(localStorage.getItem("MemoverseUserData"));
    const localStorageData = localStorage.getItem("MemoverseOrderLocalData");
    setUserName(storedData !== null ? storedData : userNameData.userName);

    if (localStorageData) {
      try {
        const parsedData = JSON.parse(localStorageData);
        setOrderData(parsedData);
      } catch (error) {
        console.error("Error parsing localStorage data:", error);
      }
    }
  }, []);

  const [variantOpt] = useState(
    JSON.parse(localStorage.getItem("MemoverseOrderLocalData"))?.varOption
  );

  // useEffect(() => {
  //   setShowHideParagraph(orderData && orderData.planExpiration);
  // }, [orderData]);

  const renewalPlan = (planExpiration, planExpirationDate) => {
    const currentDate = new Date();
    if (planExpiration === "days") {
      const expirationDate = addDays(currentDate, planExpirationDate);
      return format(expirationDate, "MMMM dd, yyyy");
    } else if (planExpiration === "month" || planExpiration === "months") {
      const expirationDate = addMonths(currentDate, planExpirationDate);
      return format(expirationDate, "MMMM dd, yyyy");
    }
    return "Invalid plan expiration";
  };

  const subscriptionText = (
    planExpirationDate,
    planExpiration,
    planName,
    textEnable,
    trialText
  ) => {
    return `${planExpirationDate}-${planExpiration} ${planName}${
      textEnable ? trialText : ""
    }`;
  };

  const [isLoading, setIsLoading] = useState(true);
  const { scrollRefTop, scrollToTopComponent } = useScroll();
  const PageContainerRef = useRef(null);

  useEffect(() => {
    scrollRefTop.current = PageContainerRef.current;
  }, [scrollRefTop]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      scrollToTopComponent();
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    }, 100);
  }, [scrollToTopComponent]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.container}>
      <div ref={PageContainerRef} />
      <Header />
      {abTestDesignVariant === "A" ? (
        <TopComponent
          userName={userName}
          dataComponent={dataComponent}
          orderData={orderData}
          subscriptionText={subscriptionText}
          renewalPlan={renewalPlan}
        />
      ) : (
        <TopComponentVariantB
          userName={userName}
          dataComponent={dataComponent}
          orderData={orderData}
          subscriptionText={subscriptionText}
          renewalPlan={renewalPlan}
        />
      )}
      <StepsContainer
        dataComponent={dataComponent}
        showHideParagraph={showHideParagraph}
      />
      <BottomComponent
        dataComponent={dataComponent.bottomSection}
        variantOpt={variantOpt}
      />
      <FooterContainer />
    </div>
  );
};

export default RedesignThankYou;
