import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../context/ConfigDataContext";
import { useEvent } from "../../context/EventContext";
import LogoGlobal from "../templates/Logo/LogoGlobal";

import styles from "./LandingB_two.module.css";

const LandingBTwo = () => {
  const { findDataComponent } = useConfig();
  const { analyticsEvent } = useEvent();
  const [dataComponent, setDataComponent] = useState(null);

  const fireAnalytics = useCallback(() => {
    analyticsEvent("landingPageView");
  }, [analyticsEvent]);

  useEffect(() => {
    fireAnalytics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchDataComponent = async () => {
      const data = await findDataComponent(1, "LandingB");
      setDataComponent(data);
    };
    fetchDataComponent();
  }, [findDataComponent]);

  if (!dataComponent) {
    return null;
  }

  const specialHighlightedText = dataComponent.highlightedText || "";
  const headerTextParts = specialHighlightedText.split(
    dataComponent.textSpecialStyle || ""
  );

  const mainTextWithSpecialStyle = (
    <span>
      {headerTextParts[0]}
      {
        <span className={styles.mainSpecialText}>
          {dataComponent.textSpecialStyle}
        </span>
      }
      {headerTextParts[1]}
      <br />
      <br />
      {dataComponent.mainText}
    </span>
  );

  const handleGetStarted = () => {
    analyticsEvent("getStartedClicked", { page: "LandingBTwo" });
  };

  const prizesData = [
    { id: 1, imgSrc: "/img/prizes_01.png", alt: "Kickstarter" },
    { id: 2, imgSrc: "/img/prizes_02.png", alt: "Google Brand Accelerator" },
    { id: 3, imgSrc: "/img/prizes_03.png", alt: "Memory Champion" },
    { id: 4, imgSrc: "/img/prizes_04.png", alt: "Techcrunch" },
  ];

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <LogoGlobal />
        </div>

        <div className={styles.container}>
        <h1 className={styles.headerText}>{dataComponent.headerText}</h1>
          {/* <div className={styles.headerText}>{dataComponent.headerText}</div> */}
          <div className={styles.lowHeadText}>{dataComponent.lowHeadText}</div>
          <div className={styles.mainText}>{mainTextWithSpecialStyle}</div>
        </div>
        <Link to="/quiz/1" className={styles.link}>
          <button onClick={handleGetStarted} className={styles.containerButton}>
            <span className={styles.buttonText}>
              {dataComponent.buttonText}
            </span>
            <img
              src="../img/Arrow_right_landing.png"
              alt="Arrow_right_landing"
              className={styles.buttonArrow}
            />
          </button>
        </Link>
        <div className={styles.prizesContainer}>
          {prizesData.map((prize) => (
            <div key={prize.id} className={styles.prizesFrame}>
              <img
                src={prize.imgSrc}
                alt={prize.alt}
                className={styles.prizesFrameImg}
              />
            </div>
          ))}
        </div>
        <div className={styles.LandingFooter}>
          <div className={styles.FooterPolicyGroup}>
            <span className={styles.FooterContainerTextLink}>
              <a
                href="https://memoryos.com/privacy-policy"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            <span className={styles.FooterContainerTextLink}>
              <a
                href="https://memoryos.com/terms"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </span>
            <span className={styles.FooterContainerTextLink}>
              {" "}
              <a
                href="https://memoryos.com/terms"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Subscription Policy
              </a>
            </span>
          </div>
          <div className={styles.FooterCopyright}>
            <span className={styles.FooterContainerText}>
              © Copyright {new Date().getFullYear()} Encoder Inc., Delaware, the
              USA
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBTwo;
