import React, { useEffect } from "react";
import { useABtest } from "../../../../../context/ABTestContext";
import { useTimer } from "../../../../../context/TimerContext";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";
import { useConfig } from "../../../../../context/ConfigDataContext";

import PaymentButton from "../../PaymentButton/PaymentButton";
import FirstPlanOption from "./PlanOption/FirstPlanOption";
import SecondPlanOption from "./PlanOption/SecondPlanOption";
import ToggleButton from "../ToggleButton/ToggleButton";

import Loading from "../../../ABtestPlanPage/Loading/Loading";
import styles from "./VariantСStyle.module.css";
import SpecialOfferButton from "../../SpecialOfferButton/SpecialOfferButton";

const VariantС = () => {
  const { showHideElement } = useTimer();
  const {
    isActive,
    setIsActive,
    firstOptionRender,
    secondOptionRender,
    selectedABOption,
    setSelectedOption,
    handleOptionChangeWithToggle,
  } = useABtest();

  const { downSaleActive } = useFirebaseConfig();

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (isActive) {
      setSelectedOption(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "ToggleButton");

  return (
    <div className={styles.PlanContainer}>
      <div
        className={styles.OptionsPlanContainer}
        style={{
          height: downSaleActive !== true ? "190px" : "215px",
        }}
      >
        {firstOptionRender.length === 0 ? (
          <Loading />
        ) : (
          <>
            <FirstPlanOption
              firstOptionRender={
                !isActive ? firstOptionRender[0] : firstOptionRender[1]
              }
              selectedOption={selectedABOption}
              handleOptionChangeWithToggle={handleOptionChangeWithToggle}
            />
            <SecondPlanOption
              secondOptionRender={secondOptionRender[0]}
              selectedOption={selectedABOption}
              handleOptionChangeWithToggle={handleOptionChangeWithToggle}
            />
          </>
        )}
      </div>
      {}
      {data.showHideToggle && showHideElement && (
        <ToggleButton handleToggle={handleToggle} />
      )}
      {isActive ? <SpecialOfferButton /> : <PaymentButton />}
    </div>
  );
};

export default VariantС;
